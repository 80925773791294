import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { filter } from 'rxjs/operators';
import { ThanhVienBlockTransation } from 'src/app/shared/ThanhVienBlockTransation.model';
import { ThanhVienBlockTransationService } from 'src/app/shared/ThanhVienBlockTransation.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-block-detail-component',
  templateUrl: './block-detail-component.component.html',
  styleUrls: ['./block-detail-component.component.css']
})
export class BlockDetailComponentComponent implements OnInit {

  paramParentName : string;
  displayedColumns: string[] = ['TransactionHash', 'Sender', 'SendTo','Time', 'Status'];
  dataSource = new MatTableDataSource<ThanhVienBlockTransation>();
  toltalThanhVien = new ThanhVienBlockTransation();
  intervalId: any;

  constructor( 
    public router: Router,
    public ThanhVienBlockTransationService: ThanhVienBlockTransationService,
    private route: ActivatedRoute
  ) { }



  ngOnInit(): void {
    this.getParentNameFromTable();
    
  }

  getParentNameFromTable(){
    this.route.paramMap.subscribe(params => {
      this.paramParentName = params.get('ParentName');
      if(this.paramParentName){
        this.ThanhVienBlockTransationService.IsShowLoading = true;
        this.ThanhVienBlockTransationService.GetTransactionByBlockIDAsync(this.paramParentName).subscribe(
          (res: ThanhVienBlockTransation[]) => {        
            this.dataSource.data = res;
            this.getToltalTransactionFromBlockId();
            this.ThanhVienBlockTransationService.IsShowLoading = false;
          },
          err => {
            this.ThanhVienBlockTransationService.IsShowLoading = false;
          }
        );
      }
    });
  }

  getToltalTransactionFromBlockId(){
    this.ThanhVienBlockTransationService.GetTolTalTrancsationFromBlockID(this.paramParentName).subscribe(
      (res: ThanhVienBlockTransation) => {        
        this.toltalThanhVien = res;
      },
      err => {
        this.ThanhVienBlockTransationService.IsShowLoading = false;
      }
    );
  }

  removeClasses(){
    return {
      'mat-header-row': false,
      'cdk-header-row': false,
      'mat-header-cell': false,
      'cdk-header-cell': false,
      'cdk-column': false
    };
  }
}
