import { Component } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { DownloadService } from 'src/app/shared/Download.service';
import { NotificationService } from 'src/app/shared/Notification.service';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  isLoginRoute: boolean = false;
  isSignup : boolean = false;
  isLoginSuccess :boolean = false;
  
  PageTitle = environment.PageTitle;
  PageTitleShort = environment.PageTitleShort;
  domainName = environment.DomainDestination;
  domainURL = environment.DomainURL;
  queryString: string = environment.InitializationString;
  queryStringSub: string = environment.InitializationString;
  Token: string = environment.InitializationString;
  constructor(
    public router: Router,
    public DownloadService: DownloadService,
    public NotificationService: NotificationService,
    public ThanhVienService : ThanhVienService
  ) {
   
  }
  ngOnInit(): void {
    this.updateIsLoginRoute();
    this.checkLogin()
  }
  ngAfterViewInit() {
  }

  checkLogin(){
    this.isLoginSuccess = this.ThanhVienService.isLoginsuccess;
  }

  Logout() {
    localStorage.setItem(environment.Token, environment.InitializationString);
    localStorage.setItem(environment.ThanhVienID, environment.InitializationString);
    this.router.navigate(['/' + environment.Login]);
  } 
  updateIsLoginRoute() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isLoginRoute = this.router.url === '/Login';
        console.log(this.isLoginRoute);
      }
    });
  }
}
