<div class="content-wrapper">
  <div class="container-full">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="d-flex align-items-center">
        <div class="me-auto">
          <h4 class="page-title">Transactions Details</h4>
        </div>
      </div>
    </div>
    <!-- Main content -->
    <section class="content">
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="box">
            <div class="box-body">
              <h4><a href="#">TransactionsHash</a></h4>
              <div class="row justify-content-between">
                <div class="col-md-12">
                  <h5>
                    <span class="badge badge-xl badge-secondary me-5 pull-left" style="width: 100%;text-align:left;"><a
                        href="#">{{transactionHashId}}</a></span>
                    <i class="fa fa-long-arrow-right text-success px-15"></i>
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="box-body">
              <h4><a href="#">TransactionsHash Origanal</a></h4>
              <div class="row justify-content-between">
                <div class="col-md-12">
                  <h5>
                    <span class="badge badge-xl badge-secondary me-5 pull-left" style="width: 100%;text-align:left;"><a
                        href="#">{{detailThanhVienBlockTransaction.Note}}</a></span>
                    <i class="fa fa-long-arrow-right text-success px-15"></i>
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="box-body">
              <h4><a href="#">Block ID</a></h4>
              <div class="row justify-content-between">
                <div class="col-md-12">
                  <h5>
                    <span class="badge badge-xl badge-secondary me-5 pull-left" style="width: 100%;text-align:left;"><a
                        href="blockdetail.html">{{detailThanhVienBlockTransaction.ParentName}}</a></span>
                    <i class="fa fa-long-arrow-right text-success px-15"></i>
                  </h5>
                </div>
              </div>
              <div class="mt-30" *ngIf="detailThanhVienBlockTransaction.Note != ''">
                <span class="badge badge-xl badge-primary me-5 pull-left">45 Transactions</span>
              </div>
            </div>
          </div>

          <div class="box">
            <div class="box-header with-border">
              <h4 class="box-title">Overview</h4>
            </div>
            <div class="box-body">
              <div class="table-responsive">
                <table class="table table-striped table-hover no-margin">
                  <tbody>
                    <tr>
                      <td>Origanal Transaction</td>
                      <td>FALSH</td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>1235</td>
                    </tr>
                    <tr>
                      <td>Sender</td>
                      <td>2019-02-05 13:57:30</td>
                    </tr>
                    <tr>
                      <td>Function</td>
                      <td>Block: 123456</td>
                    </tr>
                    <tr>
                      <td>Amount</td>
                      <td>
                       0 GAS
                      </td>
                    </tr>
                    <tr>
                      <td>Confirmations</td>
                      <td>25 Confirmations</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="box">
            <div class="box-header with-border">
              <h4 class="box-title">BlockDetail</h4>
            </div>
            <div class="box-body">
              <div class="table-responsive">
                <table class="table table-striped table-hover no-margin">
                  <tbody>
                    <tr>
                      <td>Sequence Number</td>
                      <td>0.123456789 BTC</td>
                    </tr>
                    <tr>
                      <td>Timestamp:</td>
                      <td>0.987456321 BTC</td>
                    </tr>
                    <tr>
                      <td>GAS Fee</td>
                      <td>12,142.147 sat/B</td>
                    </tr>
                    <tr>
                      <td>GAS Unit Price</td>
                      <td>3,123.537 sat/WU</td>
                    </tr>
                    <tr>
                      <td>Max GAS</td>
                      <td>0.123456789 BTC</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
<app-loading *ngIf="ThanhVienBlockTransationService.IsShowLoading"></app-loading>