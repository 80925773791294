import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NotificationService } from 'src/app/shared/Notification.service';
import { environment } from 'src/environments/environment';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  isSignUp: boolean = false;
  private routerSub: Subscription;
  constructor(
    public router: Router,
    public NotificationService: NotificationService,
    public ThanhVienService: ThanhVienService,
  ) {

  }
  
  ngOnInit(): void {
   this.signUpRoute();
  }

  ngOnDestroy(): void {
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
  }
  Login() { 
    debugger   
    this.ThanhVienService.IsShowLoading = true;    
    if (this.ThanhVienService.FormData.DanhMucUngDungID == null) {
      this.ThanhVienService.FormData.DanhMucUngDungID = environment.DanhMucUngDungID;
    }   
    this.ThanhVienService.AuthenticationAsync().subscribe(
      res => {
        this.ThanhVienService.IsShowLoading = false;
        this.ThanhVienService.FormDataLogin = res as ThanhVien;
        if (this.ThanhVienService.FormDataLogin) {
          if (this.ThanhVienService.FormDataLogin.HTMLContent) {
            localStorage.setItem(environment.Token, this.ThanhVienService.FormDataLogin.HTMLContent);
            localStorage.setItem(environment.ThanhVienID, this.ThanhVienService.FormDataLogin.ID.toString());
            localStorage.setItem(environment.ThanhVienParentID, this.ThanhVienService.FormDataLogin.ParentID.toString());
            localStorage.setItem(environment.ThanhVienTaiKhoan, this.ThanhVienService.FormDataLogin.TaiKhoan);
            localStorage.setItem(environment.ThanhVienHoTen, this.ThanhVienService.FormDataLogin.Name);
            localStorage.setItem(environment.ThanhVienFileName, this.ThanhVienService.FormDataLogin.FileName);
            this.ThanhVienService.isLoginsuccess = true;
            this.router.navigate(['/' + environment.Homepage]);
          }
          else {
            this.NotificationService.success(environment.LoginNotSuccess);
            this.ThanhVienService.isLoginsuccess = false;
          }
        }
      },
      err => {
        this.NotificationService.warn(environment.LoginNotSuccess);
        this.ThanhVienService.IsShowLoading = false;
        this.ThanhVienService.isLoginsuccess = false;
       
      }
    );
  }
  signUpRoute() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isSignUp = this.router.url === '/Signup';
        console.log(this.isSignUp);
      }
    });
  }
}
