import { Base } from "./Base.model";

export class ThanhVienBlockTransation extends Base{

NgayGhiNhan?: Date;
DiaChiChuyen?: string;
DiaChiNhan?: string;
SoLuongTransation?: number;
SoLuongGas?: number;
SoLuongThang?: number;
SoLuong?: number;
ThanhTienSauCung?: number;
KhuVuc?: string;
Page?:number;
TypeName?: string;
Description?: string;
Status?:string;
}


