import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CKEditorModule } from 'ngx-ckeditor';
import { ChartsModule } from 'ng2-charts';
import { CookieService } from 'ngx-cookie-service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MaterialModule } from './material/material.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { NotificationService } from './shared/Notification.service';
import { AppComponent } from './app.component';
import { LoadingComponent } from './loading/loading.component';
import { LoginComponent } from './login/login.component';
import { FooterComponent } from './footer/footer.component';
import { ContentMainComponent } from './content-main/content-main.component';
import { SignupComponent } from './signup/signup.component';
import { PagenotfoundcomponentComponent } from './pagenotfoundcomponent/pagenotfoundcomponent.component';
import { TransationDetailComponentComponent } from './transation-detail-component/transation-detail-component.component';
import { BlockDetailComponentComponent } from './block-detail-component/block-detail-component.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AccountComponentComponent } from './account-component/account-component.component';





@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
    LoginComponent,
    FooterComponent,
    ContentMainComponent,
    SignupComponent,
    PagenotfoundcomponentComponent,
    TransationDetailComponentComponent,
    BlockDetailComponentComponent,
    AccountComponentComponent,


  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    GoogleMapsModule,
    ChartsModule,
    CKEditorModule,
    MatTooltipModule
  ],
  providers: [
    CookieService,
    NotificationService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
