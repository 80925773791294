<section class="error-page h-p100">
    <div class="container h-p100">
      <div class="row h-p100 align-items-center justify-content-center text-center">
          <div class="col-lg-7 col-md-10 col-12">
              <div class="rounded10 p-50">
                  <img src="../images/auth-bg/404.jpg" class="max-w-200" alt="" />
                  <h1>Page Not Found !</h1>
                  <h3>looks like, page doesn't exist</h3>
                  <div class="my-30"><a href="index.html" class="btn btn-danger">Back to dashboard</a></div>				  
              </div>
          </div>				
      </div>
    </div>
</section>