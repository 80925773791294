import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ThanhVienBlockTransation } from 'src/app/shared/ThanhVienBlockTransation.model';
import { ThanhVienBlockTransationService } from 'src/app/shared/ThanhVienBlockTransation.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-content-main',
  templateUrl: './content-main.component.html',
  styleUrls: ['./content-main.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ContentMainComponent implements OnInit, OnDestroy {
  isLoginRoute: boolean = false;
  isAnimation: boolean = false;
  displayedColumns: string[] = ['TransactionHash', 'TimeSpamp', 'Status', 'Type', 'Sender', 'SendTo', 'Block', 'Function', 'Amount'];
  dataSource = new MatTableDataSource<ThanhVienBlockTransation>();
  previousData: ThanhVienBlockTransation[] = [];
  changesMap: { [key: string]: boolean } = {};
  intervalId: any;
  loadingData: boolean = false;
  randomRows: Set<string> = new Set<string>();

  constructor(
    public router: Router,
    public ThanhVienBlockTransationService: ThanhVienBlockTransationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loadInitialData();
    this.openLoginorSignPage();
  }

  openLoginorSignPage(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (this.isLoginRoute === false) {
        this.isLoginRoute = event.urlAfterRedirects === '/Login';
      } else {
        this.isLoginRoute = event.urlAfterRedirects === '/Signup';
      }
    });
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  loadInitialData() {
    this.loadingData = true;
    if(this.dataSource.data.length <= 0 ){
      this.ThanhVienBlockTransationGetLastAmount();
    }
    this.startAutoRefresh();
  }

  startAutoRefresh() {
    this.intervalId = setInterval(() => {
      if (!this.loadingData) {
        this.applyRandomRowAnimations();
        this.ThanhVienBlockTransationGetLastAmount(); 
      }
    }, 5000); // Thời gian làm mới tự động
  }

  ThanhVienBlockTransationGetLastAmount(callback?: () => void) {  
    if (this.ThanhVienBlockTransationService) {
      this.ThanhVienBlockTransationService.GetLastAmountThanhVienTransactionToListAsync().subscribe(
        (res: ThanhVienBlockTransation[]) => {
          this.updateData(res);
          this.applyRandomRowAnimations();
          if (callback) {
            callback();
          }
        },
        err => {
          if (callback) {
            callback();
          }
        }
      );
    }
  }

  updateData(newData: ThanhVienBlockTransation[]) {
    if (newData.length >= 21) {
      this.dataSource.data = newData; // Cập nhật dataSource với dữ liệu mới
      this.randomRows.clear(); // Xóa các dòng đã được đánh dấu trước đó
    }
  }

  applyRandomRowAnimations() {
    const selectedRows = this.selectRandomRowsForAnimation(this.dataSource.data); // Chọn ngẫu nhiên 10 hàng
    this.applyRowAnimations(selectedRows);
  }

  selectRandomRowsForAnimation(data: ThanhVienBlockTransation[]) {
    const shuffledData = [...data].sort(() => Math.random() - 0.5); // Trộn dữ liệu ngẫu nhiên
    return shuffledData.slice(0, 10).map(row => row.Code); // Chọn 10 hàng đầu tiên và trả về mã Code của chúng
  }

  applyRowAnimations(selectedCodes: string[]) {
    selectedCodes.forEach(code => {
      this.randomRows.add(code); // Đánh dấu các dòng được chọn
    });

    // Gọi change detection để cập nhật lớp CSS cho các dòng đã đánh dấu
    this.cd.detectChanges();

    // Đặt trạng thái loading sau khi hoàn tất
    setTimeout(() => {
      this.loadingData = false;
    }, 800);
  }

  removeClasses() {
    return {
      'mat-header-row': false,
      'cdk-header-row': false,
      'mat-header-cell': false,
      'cdk-header-cell': false,
      'cdk-column': false
    };
  }
}
