<div class="content-wrapper">
  <div class="d-flex align-items-center">
    <div class="me-auto">
      <h3 class="page-title">Blocks Details</h3>
    </div>
  </div>

  <!-- Main content -->
  <section class="content">
    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="box">
          <div class="box-header with-border">
            <h4 class="box-title">Summary</h4>
          </div>
          <div class="box-body">
            <div class="table-responsive">
              <table class="table table-striped table-hover no-margin">
                <tbody>
                  <tr>
                    <td>Block ID</td>
                    <td>
                      <a href="#">{{ paramParentName }}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>Hash 160</td>
                    <td>
                      <a href="#">3df2dfds24478454d445d5sf454df545d</a>
                    </td>
                  </tr>
                  <tr>
                    <td>Total Transactions</td>
                    <td>
                      <span class="badge badge-xl badge-warning me-5 pull-left"
                        >{{this.toltalThanhVien.SoLuong}} Transations</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-12">
        <div class="box">
          <div class="box-header with-border">
            <h4 class="box-title">Transactions</h4>
          </div>
          <div class="box-body">
            <div class="table-responsive">
              <table class="table table-striped table-hover no-margin">
                <tbody>
                  <tr>
                    <td>Total Faile Transactions</td>
                    <td><span class="text-success">150</span></td>
                    <td><i class="fa fa-bar-chart"></i></td>
                  </tr>
                  <tr>
                    <td>Total Success Transation</td>
                    <td>
                      <span class="text-success">30</span>
                    </td>
                    <td><i class="fa fa-bar-chart"></i></td>
                  </tr>
                  <tr>
                    <td>Total Pending Transation</td>
                    <td><span class="text-success">120 </span></td>
                    <td><i class="fa fa-bar-chart"></i></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-12">
        <div class="box">
          <div class="box-body">
            <img src="./images/frame.png" alt="codeQR" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="box">
          <div class="box-header with-border">
            <h4 class="box-title">Latest Transactions</h4>
          </div>
          <div class="box-body">
            <div class="table-responsive">
              <table
                class="table table-bordered no-margin"
                mat-table
                [dataSource]="dataSource"
              >
                <ng-container matColumnDef="TransactionHash">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    [ngClass]="removeClasses()"
                  >
                  TransactionHash
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <a
                      routerLink="/TransationDetail/{{ element.Code }}/"
                      class="text-primary hover-primary"
                      ><span class="custom-span">{{ element.Code }}</span></a
                    >
                  </td>
                </ng-container>
                <ng-container matColumnDef="Sender">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    [ngClass]="removeClasses()"
                  >
                    Sender
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <a href="#" class="text-primary hover-primary">{{
                      element.DiaChiChuyen
                    }}</a>
                  </td>
                </ng-container>
                <ng-container matColumnDef="SendTo">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    [ngClass]="removeClasses()"
                  >
                    SendTo
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <a href="#" class="text-primary hover-primary">{{
                      element.DiaChiNhan
                    }}</a>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Status">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    [ngClass]="removeClasses()"
                  >
                    Status
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span
                      class="label"
                      [ngClass]="{
                        'label-success': element.Display === 'Success',
                        'label-warning': element.Display === 'Pending',
                        'label-info-fail': element.Display === 'Failed'
                      }"
                      >{{ element.Display }}
                    </span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Time">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    [ngClass]="removeClasses()"
                  >
                    TimeSpamp
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <time
                      class="timeago"
                      [attr.datetime]="element.CreatedDate"
                      title="{{ element.CreatedDate }}"
                      >{{ element.CreatedDate | date : "short" }}</time
                    >
                  </td>
                </ng-container>
                 <!-- Custom Header Row -->
                <thead>
                  <tr>
                    <th mat-header-cell *matHeaderCellDef>Transaction Hash</th>
                    <th mat-header-cell *matHeaderCellDef>Sender</th>
                    <th mat-header-cell *matHeaderCellDef>SendTo</th>
                    <th mat-header-cell *matHeaderCellDef>Time</th>
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                  </tr>
                </thead>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>
              </table>
            </div>
          </div>
          <!-- /.box-body -->
        </div>
        <!-- /.box -->
      </div>
    </div>
  </section>
</div>
<app-loading
  *ngIf="ThanhVienBlockTransationService.IsShowLoading"
></app-loading>
