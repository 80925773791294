<div class="content-wrapper">
    <div class="container-full">
      <!-- Main content -->
      <section class="content">
        <!-- Search -->
        <div class="row">
          <form>
            <div class="input-group">
              <input type="search" class="form-control" placeholder="Search by Block/TransactionID" aria-label="Search" aria-describedby="button-addon2" />
              <div class="input-group-append">
                <button class="btn btn-success" type="submit" id="button-addon3">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search">
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="row">
          <div class="col-lg-12 col-8">
            <div class="box">
              <div class="box-header with-border">
                <h1 class="page-header text-center">
                  <span class="text-bold">Enterprise Blockchain Platform</span>
                </h1>
              </div>
              <div class="box-body">
                <div class="table-responsive">
                  <table mat-table [dataSource]="dataSource" class="table no-margin">
                    <ng-container matColumnDef="TransactionHash">
                      <th mat-header-cell *matHeaderCellDef [ngClass]="removeClasses()">Transaction Hash</th>
                      <td mat-cell *matCellDef="let element" [ngClass]="{'loading': randomRows.has(element.Code)}">
                        <a [routerLink]="['/TransationDetail', element.Code]" class="text-primary hover-primary">
                          <span class="custom-span">{{element.Code}}</span>
                        </a>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="TimeSpamp">
                      <th mat-header-cell *matHeaderCellDef [ngClass]="removeClasses()">TimeSpamp</th>
                      <td mat-cell *matCellDef="let element" [ngClass]="{'loading': randomRows.has(element.Code)}">
                        <time class="timeago" [attr.datetime]="element.CreatedDate" title="{{element.CreatedDate}}">{{element.CreatedDate | date:'short'}}</time>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Status">
                      <th mat-header-cell *matHeaderCellDef [ngClass]="removeClasses()">
                        <i matTooltip="• First point \n• Second point \n• Third point" matTooltipClass="custom-tooltip" class="fa fa-info-circle fs-20"></i> &nbsp;Status
                      </th>
                      <td mat-cell *matCellDef="let element" [ngClass]="{'loading': randomRows.has(element.Code)}">
                        <span class="label" [ngClass]="{'label-success': element.Display === 'Success', 'label-warning': element.Display === 'Pending', 'label-info-fail': element.Display === 'Failed', 'label': element.Display === '' }">{{element.Display}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Type">
                      <th mat-header-cell *matHeaderCellDef [ngClass]="removeClasses()">Type</th>
                      <td mat-cell *matCellDef="let element" [ngClass]="{'loading': randomRows.has(element.Code)}">
                        <span class="label label-warning">{{element.Description}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Sender">
                      <th mat-header-cell *matHeaderCellDef [ngClass]="removeClasses()">Sender</th>
                      <td mat-cell *matCellDef="let element" [ngClass]="{'loading': randomRows.has(element.Code)}">
                        <a [routerLink]="['/Accountdetail', element.DiaChiChuyen]" 
                        class="text-primary hover-primary">{{element.DiaChiChuyen}}</a>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="SendTo">
                      <th mat-header-cell *matHeaderCellDef [ngClass]="removeClasses()">SendTo</th>
                      <td mat-cell *matCellDef="let element" [ngClass]="{'loading': randomRows.has(element.Code)}">
                        <a [routerLink]="['/Accountdetail', element.DiaChiNhan]" 
                        class="text-primary hover-primary">{{element.DiaChiNhan}}</a>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Block">
                      <th mat-header-cell *matHeaderCellDef [ngClass]="removeClasses()">Block</th>
                      <td mat-cell *matCellDef="let element" [ngClass]="{'loading': randomRows.has(element.Code)}">
                        <a [routerLink]="['/Blockdetail', element.ParentName]" class="text-primary hover-primary">{{element.ParentName}}</a>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Function">
                      <th mat-header-cell *matHeaderCellDef [ngClass]="removeClasses()">Function</th>
                      <td mat-cell *matCellDef="let element" [ngClass]="{'loading': randomRows.has(element.Code)}">
                        <span class="label label-info">{{element.TypeName}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Amount">
                      <th mat-header-cell *matHeaderCellDef [ngClass]="removeClasses()">Amount</th>
                      <td mat-cell *matCellDef="let element" [ngClass]="{'loading': randomRows.has(element.Code)}">
                        <div>{{element.SoLuong}}</div>
                        <small class="fs-12">GAS : {{element.SoLuongGas}}</small>
                      </td>
                    </ng-container>
                    <!-- Custom Header Row -->
                    <thead>
                      <tr class="bg-dark">
                        <th mat-header-cell *matHeaderCellDef>TransactionHash</th>
                        <th mat-header-cell *matHeaderCellDef>TimeSpamp</th>
                        <th mat-header-cell *matHeaderCellDef>Status</th>
                        <th mat-header-cell *matHeaderCellDef>Type</th>
                        <th mat-header-cell *matHeaderCellDef>Sender</th>
                        <th mat-header-cell *matHeaderCellDef>SendTo</th>
                        <th mat-header-cell *matHeaderCellDef>Block</th>
                        <th mat-header-cell *matHeaderCellDef>Function</th>
                        <th mat-header-cell *matHeaderCellDef>Amount</th>
                      </tr>
                    </thead>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-primary"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- /.content -->
    </div>
  </div>
  <app-loading *ngIf="ThanhVienBlockTransationService.IsShowLoading"></app-loading>
  