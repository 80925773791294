<div class="content-wrapper">
    <div class="container-full">
      <!-- Content Header (Page header) -->	  
      <div class="content-header">
          <div class="d-flex align-items-center">
              <div class="me-auto">
                  <h4 class="page-title">Account Profile</h4>
              </div>
          </div>
      </div>

      <!-- Main content -->
      <section class="content">
          <div class="row">				
              <div class="col-xl-4 col-lg-5">
                <!-- Profile Image -->
                <div class="box">
                  <div class="box-body box-profile">					
                    <h3 class="profile-username text-center my-10">Sâm Ngọc Linh</h3>

                    <h4 class="text-center mt-0"><i class="fa fa-envelope-o me-10"></i>samngoclinhcms@gmail.com</h4>

                    <div class="row social-states">
                        <div class="col-6 text-end"><a href="#" class="link text-white"><i class="ion ion-ios-people-outline"></i> 254</a></div>
                        <div class="col-6 text-start"><a href="#" class="link text-white"><i class="ion ion-images"></i> 54</a></div>
                    </div>

                    <div class="row">
                      <div class="col-12">
                          <div class="media-list media-list-hover media-list-divided w-p100 mt-30">
                              <h5 class="media media-single p-15">
                                <i class="fa fa-arrow-circle-o-right me-10"></i><span class="title">The Wallet</span>
                                <span class="badge badge-sm badge-info mb-10">08xx47ssdd372...</span>
                              </h5>
                              <h5 class="media media-single p-15">
                                <i class="fa fa-arrow-circle-o-right me-10"></i><span class="title">Paid To Money</span>
                                <span class="badge badge-sm badge-danger mb-10">12.000.000</span>
                              </h5>
                              <h5 class="media media-single p-15">
                                <i class="fa fa-arrow-circle-o-right me-10"></i><span class="title">Account Status</span>
                                <span class="badge badge-sm badge-danger mb-10">Wait For Payment</span>
                              </h5>
                              <h5 class="media media-single p-15">
                                <i class="fa fa-arrow-circle-o-right me-10"></i><span class="title">Tax Code</span>
                                <span class="badge badge-sm badge-info mb-10">0847472372</span>
                              </h5>
                          </div>
                      </div>
                      <h4 class="title w-p100 mt-10 mb-0 p-20 text-primary">Usage History</h4>
                      <div class="col-12">
                          <div class="media-list media-list-hover w-p100 mt-0">
                              <h5 class="media media-single py-10 px-0 w-p100 justify-content-between">
                                    <p>
                                    <i class="fa fa-circle text-danger pe-10 fs-12"></i>Period of use
                                    <span class="subtitle ps-20 mt-10">From &nbsp<span class="text-danger">12/03/2020 </span> <span class="text-danger">- 12/03/2021</span></span>						  
                                    </p>
                                    <p class="text-end pull-right"><span class="badge badge-sm badge-success mb-10">Active</span><br>1000 HTX</p>
                              </h5>
                              <h5 class="media media-single py-10 px-0 w-p100 justify-content-between">
                                  <p>
                                  <i class="fa fa-circle text-danger pe-10 fs-12"></i>Period of use
                                  <span class="subtitle ps-20 mt-10">From &nbsp<span class="text-danger">12/03/2022 </span> <span class="text-danger">- 12/03/2023</span></span>						  
                                  </p>
                                  <p class="text-end pull-right"><span class="badge badge-sm badge-info mb-10">Pending</span><br>1000 HTX</p>
                            </h5>
                            <h5 class="media media-single py-10 px-0 w-p100 justify-content-between">
                              <p>
                              <i class="fa fa-circle text-danger pe-10 fs-12"></i>Period of use
                              <span class="subtitle ps-20 mt-10">From &nbsp<span class="text-danger">12/03/2023 </span> <span class="text-danger">- 10/07/2024</span></span>						  
                              </p>
                              <p class="text-end pull-right"><span class="badge badge-sm badge-danger mb-10">Expired</span><br>0 HTX</p>
                        </h5>
                          </div>
                      </div>
                    </div>
                  </div>
                  <!-- /.box-body -->
                </div>
                <!-- /.box -->
              </div>
              <!-- /.col -->
              <div class="col-xl-8 col-lg-7">
                <div class="box">
                  <div class="box-header with-border">
                    <h4 class="box-title">Account details</h4>
                  </div>
                  <!-- /.box-header -->
                  <div class="box-body">
                      <div class="table-responsive">
                        <table class="table table-striped table-hover no-margin">
                          <tbody>
                            <tr>
                              <td>Email</td>
                              <td>samngoclinhcms@gmail.com</td>
                            </tr>
                            <tr>
                              <td>Phone Number</td>
                              <td>0847564224</td>
                            </tr>
                            <tr>
                              <td>General Information</td>
                              <td>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam</td>
                            </tr>

                            <tr>
                              <td>Expiration date</td>
                              <td>12/03/2023 hh:mm:ss</td>
                            </tr>
                            <tr>
                              <td>Payment Amount</td>
                              <td><span class="text-danger">12.000.000 VND</span></td>
                            </tr>
                            <tr>
                              <td>Payment (Date)</td>
                              <td>
                                  <span class="text-danger">Unpaid</span>
                              </td>
                            </tr>
                            <tr>
                              <td>Total Transactions</td>
                              <td>
                                <span>900</span>
                              </td>
                            </tr>
                            <tr>
                              <td>Current Tx Hash</td>
                              <td>10,000 TXH</td>
                            </tr>
                            <tr>
                              <td>Total Month</td>
                              <td>
                                <a href="#"><span>25 Month</span></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  <!-- /.box-body -->
                </div>
              </div>
          </div>
      </section>
      <!-- /.content -->
    
    </div>
</div>