
<div  class="layout-top-nav light-skin theme-primary fixed">
    <div  class="wrapper">
        <header class="main-header">
            <div class="inside-header">
              <div class="d-flex align-items-center logo-box justify-content-start">
                <!-- Logo -->
                <div>
                    <a routerLink="/Homepage" class="logo">
                        <!-- logo-->
                        <div class="logo-lg">
                            <span class="light-logo"><img src="assets/images/logo/DGKchain_logo.png" alt="logo" /></span>
                            <span class="dark-logo"><img src="assets/images/logo/DGKchain_logo.png" alt="logo" /></span>
                        </div>
                    </a>
                </div>
              </div>
                <!-- Menu -->
                <div class="menu">
                    <!-- <button class="waves-effect waves-light btn btn-dark mb-5"><i class="bi bi-moon"></i>Dark Mode</button> -->
                    <!-- Sample menu definition -->
                    <ul id="main-menu" class="sm sm-blue">
                        <li *ngIf="!ThanhVienService.isLoginsuccess">
                            <a [routerLink]="['/Login']" routerLinkActive="active" id="login" style="display: block;"><i data-feather="lock"></i>Login </a>
                        </li>
                        <!-- User Account-->
                        <li *ngIf="ThanhVienService.isLoginsuccess" class="dropdown user user-menu" >
                            <a href="#" class="waves-effect waves-light dropdown-toggle btn-primary-light"
                                data-bs-toggle="dropdown" title="User">
                                <i data-feather="user"></i>
                                TaiKhoanA
                            </a>
                            <ul class="dropdown-menu animated flipInX">
                                <li class="user-body">
                                    <a class="dropdown-item" href="#"><i class="ti-user text-muted me-2"></i> Profile</a>
                                    <a class="dropdown-item" href="#"><i class="ti-wallet text-muted me-2"></i> My
                                        Wallet</a>
                                    <a class="dropdown-item" href="#"><i class="ti-settings text-muted me-2"></i>
                                        Settings</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#"><i class="ti-lock text-muted me-2"></i> Logout</a>
                                </li>
                            </ul>
                        </li>
                        <!--Connect-->
                        <li class="dropdown user user-menu" style="display: block;">
                            <a href="#" class="waves-effect waves-light dropdown-toggle btn-primary-light"
                                data-bs-toggle="dropdown" title="Connect"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                                    height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round" class="feather feather-refresh-ccw">
                                    <polyline points="1 4 1 10 7 10"></polyline>
                                    <polyline points="23 20 23 14 17 14"></polyline>
                                    <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15">
                                    </path>
                                </svg>
                                Connect</a>
                            <ul class="dropdown-menu animated flipInX">
                                <li>
                                    <a href="#">
                                        <i class="ti-lock text-muted me-2"></i>
                                        Privacy</a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="ti-check-box text-muted me-2"></i>
                                        Terms</a>
                                </li>
                                <li>
                                    <a href="#"> <i class="ti-money text-muted me-2"></i>Price</a>
                                </li>
                                <li>
                                    <a href="#"> <i class="ti-settings text-muted me-2"></i>
                                        API Developer</a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="ti-paragraph text-muted me-2"></i>
                                        White Paper</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
        <router-outlet></router-outlet>
    </div> 
    <app-footer></app-footer>
</div>


