import { Component, OnInit } from '@angular/core';
import { ThanhVienBlockTransation } from 'src/app/shared/ThanhVienBlockTransation.model';
import { ThanhVienBlockTransationService } from 'src/app/shared/ThanhVienBlockTransation.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-transation-detail-component',
  templateUrl: './transation-detail-component.component.html',
  styleUrls: ['./transation-detail-component.component.css']
})
export class TransationDetailComponentComponent implements OnInit {
  transactionHashId: string;
  detailThanhVienBlockTransaction = new ThanhVienBlockTransation();
  isOriganalTransation: boolean = false;
  constructor(
    public ThanhVienBlockTransationService: ThanhVienBlockTransationService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getTransactionHashFromTable();
  }

  getTransactionHashFromTable() {
    debugger
    this.route.paramMap.subscribe(params => {
      this.transactionHashId = params.get('Code');
      this.ThanhVienBlockTransationService.IsShowLoading = true;
      if (this.transactionHashId) {
        this.ThanhVienBlockTransationService.IsShowLoading = false;
        this.ThanhVienBlockTransationService.GetDetailTrancsationFromID(this.transactionHashId).subscribe(
          (res: ThanhVienBlockTransation) => {
            this.detailThanhVienBlockTransaction = res;
            this.checkOriganalTransation(this.detailThanhVienBlockTransaction.Note);
          },
          err => {
            this.ThanhVienBlockTransationService.IsShowLoading = false;
          }
        );
      }
    });
  }

  checkOriganalTransation(checkNote: string) {
    if (checkNote) {
      this.isOriganalTransation = true
    }
  }
}
