import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ThanhVienBlockTransation } from 'src/app/shared/ThanhVienBlockTransation.model';
import { BaseService } from './Base.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ThanhVienBlockTransationService extends BaseService {

    DisplayColumns001: string[] = ['STT', 'ID', 'NgayGhiNhan', 'ParentName', 'Code', 'KhuVuc', 'TypeName', 'Description', 'DiaChiChuyen', 'DiaChiNhan', 'SoLuongTransation', 'SoLuongGas', 'SoLuongThang', 'ThanhTienSauCung'];
    private dataSource = new BehaviorSubject<ThanhVienBlockTransation[]>([]);
    dataSource$ = this.dataSource.asObservable();    
    List: ThanhVienBlockTransation[] | undefined;
    ListFilter: ThanhVienBlockTransation[] | undefined;
    FormData!: ThanhVienBlockTransation;

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "ThanhVienBlockTransation";      
    }

    GetLastAmountThanhVienTransactionToListAsync(){
        let url = this.APIURL + this.Controller + '/GetLastAmountThanhVienTransactionToListAsync';
        const formUpload: FormData = new FormData();
        this.FormData.Page = environment.PageLoad;
        formUpload.append('data', JSON.stringify(this.FormData));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }

    GetTransactionByBlockIDAsync(parentName : string){
        let url = this.APIURL + this.Controller + '/GetTransactionByBlockIDAsync';
        const formUpload: FormData = new FormData();
        this.FormData.ParentName = parentName;
        formUpload.append('data', JSON.stringify(this.FormData));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }

    GetTolTalTrancsationFromBlockID(parentName : string){
        let url = this.APIURL + this.Controller + '/GetTolTalTrancsationFromBlockID';
        const formUpload: FormData = new FormData();
        this.FormData.ParentName = parentName;
        formUpload.append('data', JSON.stringify(this.FormData));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
    
    GetDetailTrancsationFromID(code : string){
        let url = this.APIURL + this.Controller + '/GetDetailTrancsationFromID';
        const formUpload: FormData = new FormData();
        this.FormData.Code = code;
        formUpload.append('data', JSON.stringify(this.FormData));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }

    updateData(newData: ThanhVienBlockTransation[]) {
        this.dataSource.next(newData);
    }
}

