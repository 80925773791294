import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { PagenotfoundcomponentComponent } from './pagenotfoundcomponent/pagenotfoundcomponent.component';
import { BlockDetailComponentComponent } from './block-detail-component/block-detail-component.component'
import { ContentMainComponent } from './content-main/content-main.component';
import { TransationDetailComponentComponent } from './transation-detail-component/transation-detail-component.component';
import { AccountComponentComponent } from './account-component/account-component.component';



const routes: Routes = [
  { path: '', redirectTo: '/Homepage', pathMatch: 'full' },
  { path: 'Homepage', component: ContentMainComponent },
  { path: 'Login', component: LoginComponent },
  { path: 'Signup', component: SignupComponent },
  { path: 'TransationDetail/:Code', component: TransationDetailComponentComponent },
  { path: 'Blockdetail/:ParentName', component: BlockDetailComponentComponent },
  { path: 'Accountdetail/:id', component: AccountComponentComponent },
  { path: '**', component: PagenotfoundcomponentComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }









































































